<template>
  <button @click="close">关闭</button>
</template>
<script>
export default {
  methods: {
    close() {
        alert("关闭")
      WeixinJSBridge.call("closeWindow");
    },
  },
};
</script>